import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

import ActionTableCell from "components/Shared/ActionTableCell"
import NoDataToDisplay from "components/Shared/NoDataToDisplay"
import { useNavigate } from "react-router-dom"

export default function SeasonsList({ seasons }) {
  const navigate = useNavigate()

  if (seasons.length === 0) {
    return <NoDataToDisplay />
  }

  return (
    <TableContainer sx={{ maxHeight: 900 }}>
      <Table stickyHeader aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell component='th'>Id</TableCell>
            <TableCell component='th'>Year</TableCell>
            <TableCell component='th'>Status</TableCell>
            <TableCell component='th'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {seasons.map((season) => (
            <TableRow key={season.id}>
              <TableCell component='td'>
                <Typography component='span' style={{ fontSize: "12px" }}>
                  {season.id}
                </Typography>
              </TableCell>
              <TableCell component='td'>
                <Typography component='span' style={{ fontSize: "12px" }}>
                  {season.year}
                </Typography>
              </TableCell>
              <TableCell component='td'>
                <Typography component='span' style={{ fontSize: "12px" }}>
                  {season.status}
                </Typography>
              </TableCell>
              <TableCell component='td'>
                <ActionTableCell
                  actions={[
                    {
                      variant: "outlined",
                      color: "success",
                      title: "Teams",
                      onClick: () => navigate(`/seasons/${season.id}/teams`),
                    },
                    {
                      variant: "outlined",
                      title: "GP",
                      onClick: () =>
                        navigate(`/seasons/${season.id}/grands-prix`),
                    },
                    {
                      variant: 'outlined',
                      title: 'Edit',
                      onClick: () => navigate(`/seasons/${season.id}/edit`),
                    },
                    <TableCell component="th" scope="row">

                
              </TableCell>
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
