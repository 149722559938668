import './App.css'

import { Route, Routes } from 'react-router-dom'

import AppLayout from "./AppLayout"
import { AuthenticationGuard } from "./common/AuthenticationGuard"
import ConstructorEditPage from "./pages/ConstructorEditPage"
import ConstructorHistoriesPage from "./pages/ConstructorsHistoriesPage"
import ConstructorHistoryEditPage from "./pages/ConstructorHistoryEditPage"
import ConstructorManufacturersAddPage from "pages/ConstructorManufacturersAddPage"
import ConstructorManufacturersEditPage from "pages/ConstructorManufacturerEditPage"
import ConstructorManufacturersPage from "pages/ConstructorManufacturersPage"
import ConstructorsCurrentSeasonPage from "pages/ConstructorsCurrentSeasonPage"
import ConstructorsPage from "./pages/ConstructorsPage"
import DriverEditPage from "pages/DriverEditPage"
import DriverHistoriesPage from "pages/DriverHistoriesPage"
import DriverHistoryEditPage from "pages/DriverHistoryEditPage"
import DriversCurrentSeasonPage from "pages/DriversCurrentSeasonPage"
import DriversPage from "pages/DriversPage"
import EngineManufacturerAddPage from "pages/EngineManufacturerAddPage"
import EngineManufacturerEditPage from "pages/EngineManufacturerEditPage"
import EngineManufacturersPage from "pages/EngineManufacturersPage"
import GrandPrixEditPage from "pages/GrandPrixEditPage"
import GrandsPrixPage from "pages/GrandsPrixPage"
import HomePage from "./pages/HomePage"
import ManufacturersPage from "pages/ManufacturersPage"
import RaceEditPage from "pages/RaceEditPage"
import SeasonGrandsPrixPage from "pages/SeasonGrandsPrixPage"
import SeasonTeamsPage from "pages/SeasonTeamsPage"
import SeasonsPage from "pages/SeasonsPage"
import TeamAddPage from "pages/TeamAddPage"
import TeamEditPage from "pages/TeamEditPage"
import TeamHistoriesAddPage from "pages/TeamHistoriesAddPage"
import TeamHistoriesPage from "pages/TeamHistoriesPage"
import TeamHistoryEditPage from "pages/TeamHistoryEditPage"
import TeamsPage from "pages/TeamsPage"
import SeasonEditPage from "pages/SeasonEditPage"

export default function App() {
  return (
    <AppLayout>
      <Routes>
        <Route path="/" element={<AuthenticationGuard component={HomePage} />} />
        <Route path="/constructors/:constructorId/histories" element={<ConstructorHistoriesPage />} />
        <Route path="/constructors" element={<ConstructorsPage />} />
        <Route path="/constructors-current-season" element={<ConstructorsCurrentSeasonPage />} />
        <Route path="/constructors/:constructorId/edit" element={<ConstructorEditPage />} />
        <Route path="/constructors/:constructorId/histories/:historyId/edit" element={<ConstructorHistoryEditPage />} />

        <Route path="/drivers" element={<DriversPage />} />
        <Route path="/drivers-current-season" element={<DriversCurrentSeasonPage />} />
        <Route path="/drivers/:driverId/edit" element={<DriverEditPage />} />
        <Route path="/drivers/:driverId/histories" element={<DriverHistoriesPage />} />
        <Route path="/drivers/:driverId/histories/:historyId/edit" element={<DriverHistoryEditPage />} />
        <Route path="/grands-prix" element={<GrandsPrixPage />} />
        <Route path="/grands-prix/:gpId/edit" element={<GrandPrixEditPage />} />
        <Route path="/grands-prix/:gpId/races/:raceId/edit" element={<RaceEditPage />} />
        <Route path="/manufacturers" element={<ManufacturersPage />} />

        <Route path="/constructor-manufacturers" element={<ConstructorManufacturersPage />} />
        <Route path="/create-constructor-manufacturers" element={<ConstructorManufacturersAddPage />} />
        <Route
          path="/constructor-manufacturers/:constructorManufacturerId/edit"
          element={<ConstructorManufacturersEditPage />}
        />

        <Route path="/engine-manufacturers" element={<EngineManufacturersPage />} />

        <Route path="/create-engine-manufacturers" element={<EngineManufacturerAddPage />} />

        <Route path="/engine-manufacturers/:engineManufacturerId/edit" element={<EngineManufacturerEditPage />} />

        <Route path="/teams" element={<TeamsPage />} />

        <Route path='/create-teams' element={<TeamAddPage />} />
        <Route path='/teams/:teamId/edit' element={<TeamEditPage />} />
        <Route
          path='/teams/:teamId/histories'
          element={<TeamHistoriesPage />}
        />
        <Route
          path='/teams/:teamId/create-team-histories'
          element={<TeamHistoriesAddPage />}
        />
        <Route
          path='/teams/:teamId/histories/:historyId/edit'
          element={<TeamHistoryEditPage />}
        />
        <Route path='/seasons' element={<SeasonsPage />} />
        <Route path='/seasons/:seasonId/teams' element={<SeasonTeamsPage />} />
        <Route
          path='/seasons/:seasonId/grands-prix'
          element={<SeasonGrandsPrixPage />}
        />
        <Route path="/seasons/:seasonId/edit" element={<SeasonEditPage />} />
      </Routes>
    </AppLayout>
  )
}
